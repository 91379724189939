.App {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);

    background-color: var(--primary-500);
    background: linear-gradient(var(--primary-500), var(--primary-500) 50%, var(--primary-700) 50%, var(--primary-700));
    height: 100vh;
    width: 100vw;
}

.App > nav {
    display: grid;
    max-height: 100vh;
    grid-template-rows: 130px 1fr;
}

.App > nav > div:nth-child(1) {
    display: grid;
    place-content: center;
}

.App > nav > div:nth-child(2) {
    background-color: var(--primary-700);
}

.Content {
    display: grid;
    grid-template-rows: 130px minmax(0, 1fr);
    height: 100%;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    background-color: white;
    overflow: hidden;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}

.ContentMain {
    height: 100%;
}

.Login svg * {
    fill: white;
}

:global(.mobile).App {
    grid-template-columns: minmax(0, 1fr);
}

:global(.mobile).App > nav {
    display: none;
}

:global(.mobile) .Content {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    grid-template-rows: 100px minmax(0, 1fr);
}