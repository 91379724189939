.Dropdown {
    position: relative;
}

.Dropdown.Right .DropdownMenu {
    right: 0;
}

.Dropdown.Left .DropdownMenu {
    left: 0;
}

.Dropdown.Center .DropdownMenu {
    left: 50%;
}

.DropdownMenu {
    top: calc(100% + 8px);
    position: absolute;
    z-index: 10;
    width: 100%;
    
    background-color: white;
    border: 2px solid var(--grey-200);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);

    border-radius: var(--corner-radius);
}

.DropdownMenu {
    opacity: 0;
    pointer-events: none;
    transform-origin: top;
    transform: translate3d(0, calc(var(--motion) * -45px), 0) scaleY(calc(100% - (var(--motion) * 20%)));
    transition: opacity calc(150ms * var(--speed)) ease-out, transform calc(150ms * var(--speed)) ease-out;
}

.Dropdown.Center .DropdownMenu {
    transform: translate3d(-50%, calc(var(--motion) * -45px), 0) scaleY(calc(100% - (var(--motion) * 20%)));
}

.DropdownMenu.Expanded {
    opacity: 1;
    transform: translate3d(0, 0, 0) scaleY(1);
    pointer-events: all;
}

.Dropdown.Center .DropdownMenu.Expanded {
    transform: translate3d(-50%, 0, 0) scaleY(1);
}


.DropdownMenu > *:first-child {
    border-top-left-radius: calc(var(--corner-radius) * 0.75);
    border-top-right-radius: calc(var(--corner-radius) * 0.75);
}

.DropdownMenu > *:last-child {
    border-bottom-left-radius: calc(var(--corner-radius) * 0.75);
    border-bottom-right-radius: calc(var(--corner-radius) * 0.75);
}
