.ResponderStaff {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    background-color: var(--grey-050);
}

.Table {
    overflow: hidden;
}

.Name {
    font-weight: bold;
    padding-left: 16px;
}

.Role {
    text-align: right;
    padding-right: 16px;
}