.Settings {
    display: grid;
    grid-template-rows: auto 1fr;
    background-color: var(--grey-050);
    height: 100%;
}

.SettingsContent {
    height: 100%;
    overflow-y: overlay;
    padding: 32px;
    box-sizing: border-box;
}

.SettingsSection {
    padding: 32px;
    background-color: white;
    border-radius: var(--corner-radius);
    margin: 0 auto 32px auto;
    max-width: 1000px;
}

.SettingsSection:last-child {
    margin-bottom: 0;
}

.SaveDepartmentsBtnRow {
    text-align: end;
    margin-bottom: 32px;
}

.SaveDepartmentsBtn {
    display: inline-block;
    margin-left: 16px;
    width: 200px;
}

.RoundCorners > div:first-child {
    border-top-right-radius: var(--corner-radius);
    border-top-left-radius: var(--corner-radius);
}

.RoundCorners > div > div:last-child {
    border-bottom-right-radius: var(--corner-radius);
    border-bottom-left-radius: var(--corner-radius);
}

.RoundCorners :global(.cell) {
    align-self: center;
}

div.ActionCell {
    display: grid;
    padding: 10px;
}

div:last-child > .ActionCell {
    padding: 17px 10px;
    align-self: end;
}

.Action {
    padding: 2px 4px;
    height: 44px;
    font-size: 14px;
}

.Departments {
    
}

.FreeTextLocation {
    margin-top: 8px;
}

.FreeTextLocation:not(.Show) {
    display: none;
}

.DepartmentsIcons {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    gap: 8px;
}

.DepartmentsIcons:empty::before {
    content: "None";
}

.DepartmentsIcons :is(svg, img, :global(.svg-mimic)) {
    height: 24px;
    width: 24px;
}

.DepartmentsIcons svg * {
    fill: var(--grey-400);
}

.Toggle {
    float: right;
}

.Toggle:global(.react-toggle--focus) :global(.react-toggle-thumb) {
    outline: 3px solid var(--primary-500);
}
.Toggle:global(.react-toggle--focus):global(.react-toggle--checked) :global(.react-toggle-thumb) {
    outline: 3px solid var(--primary-300);
}

.Toggle:global(.react-toggle) :global(.react-toggle-thumb) {
    border: none;
    transform: scale(0.8);
}

.Toggle:global(.react-toggle) :global(.react-toggle-track) {
    background-color: var(--grey-400);
}
.Toggle:global(.react-toggle):hover :global(.react-toggle-track) {
    background-color: var(--grey-500);
}
.Toggle:global(.react-toggle--checked) :global(.react-toggle-track) {
    background-color: var(--primary-500);
}
.Toggle:global(.react-toggle--checked):hover :global(.react-toggle-track) {
    background-color: var(--primary-600);
}

:global(.mobile) .SaveDepartmentsBtnRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 0;
}

:global(.mobile) .SaveDepartmentsBtn {
    margin-left: 0px;
    width: 100%;
}