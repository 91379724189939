.MaskedIcon {
    width: 100%;
    height: 100%;
    background-color: currentColor;
    -webkit-mask-image: var(--mask-url);
    mask-image: var(--mask-url);
    mask-position: center;
    -webkit-mask-position: center;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
}