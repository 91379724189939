.NewPage {
    display: grid;
    grid-template-rows: auto 1fr;
    background-color: var(--grey-050);
    height: 100%;
}

.NewPageForm {
    height: 100%;
    box-sizing: border-box;
    padding: 32px;
    overflow: overlay;
}

.Error {
    color: var(--error-color);
}

.FreeTextReason:not(.Show),
.FreeTextLocation:not(.Show) {
    display: none;
}