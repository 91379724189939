.Card {
    background-color: white;
    border-radius: var(--corner-radius);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
    position: relative;
    /* overflow: hidden; */
}

.Card:last-child {
    margin-bottom: 0;
}

.CardContents {
    padding: 32px;
    display: grid;
    grid-template-columns: auto 1fr;
}

.CardContents .Stat:nth-child(n+4) {
    grid-column: span 2;
}

.Stat {
    color: var(--primary-700);
    margin-bottom: 1em;
}

.Stat:last-child {
    margin-bottom: 0;
}

.Stat .Label {
    font-weight: bold;
    font-size: 0.9em;
}

.Stat {
    display: grid;
    grid-template-columns: 1fr 2fr;
    /* gap: 1em; */
}

.Stat.Multiline {
    grid-template-columns: 1fr;
}


/* .Card .HeaderData {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
}

.Card .ShortData {
    display: grid;
    grid-template: auto auto / auto auto;
} */

/* .Card .Data {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-top: 24px;
}

.Card :is(.Data, .HeaderData) .Label {
    font-weight: bold;
    font-size: 0.8em;
    color: var(--primary-800);
} */


.Card .DepartmentIcon {
    grid-row: span 2;

    margin-right: 32px;

    height: 32px;
    width: 32px;

    color: var(--primary-500);
    background-color: var(--primary-100);
    padding: 16px;
    border-radius: var(--corner-radius);
}

.Card .DepartmentIcon svg * {
    fill: currentColor;
}

.Card.Active .DepartmentIcon {
    color: var(--primary-010);
    background-color: var(--primary-500);
}

.Banner {
    /* width: fit-content; */
    /* justify-self: end; */
    text-align: center;
    box-sizing: border-box;
    background-color: var(--primary-200);
    color: var(--primary-700);
    padding: 0.5em 1em;
    font-weight: bold;
    font-size: 0.7em;
    border-radius: 50px;
    margin-bottom: 0.5em;
}

.Banner:empty { display: none; }
/* .Card.Active {
    background-color: var(--primary-500);
    color: white;
}

.Card.Active :is(.Data, .HeaderData) .Label {
    color: var(--primary-200);
}

.Card.Active .DepartmentIcon {
    background-color: var(--primary-400);
    color: var(--primary-100);
} */

.Respond {
    /* position: relative; */
    border: 0;
    background-color: var(--primary-500);
    color: white;
    padding: 16px;
    font-weight: bold;
    border-bottom-left-radius: var(--corner-radius);
    border-bottom-right-radius: var(--corner-radius);
}

.Respond .Label {
    display: grid;
    grid-template-columns: 1fr auto;
    /* padding: 0 16px; */
}

.Respond .Text {
    width: 100%;
    justify-self: start;
    align-self: center;
}

.Respond .Icons {
    justify-self: end;
}

.Respond .Icons {
    justify-self: end;
}

.Respond .Icon :is(svg, img, :global(.svg-mimic)) {
    width: 18px;
    height: 15px;
    box-sizing: border-box;
    padding: 2px;
}

.Respond .ExpandContent {
    padding-top: 16px;
}

.Respond:not(.Expanded) .ExpandContent {
    display: none;
}

.Respond .ExpandContent input[type='range'] {
    width: 100%;
    margin-bottom: 16px;
}

.Respond .EtaOutput {
    font-weight: bold;
    font-size: 14px;
}

.Respond .EtaLabel {
    color: var(--primary-200);
    font-weight: normal;
}

.Respond:not(.Expanded) .ExpandIcon {
    transform: rotate(180deg);
}

.ExpandContent .Actions {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    gap: 12px;
    align-items: center;
}

.Actions.Accepted {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.Respond button {
    border: 0;
    border: 2px solid white;
    background-color: white;
    color: var(--primary-500);
    padding: 4px 16px;
    border-radius: 50px;
}

.Respond button.Secondary {
    border: 2px solid white;
    box-sizing: border-box;
    color: white;
    background-color: transparent;
}

.Respond .ExpandContent button:last-of-type {
    grid-column: 3;
}

.Dropdown {
    padding: 16px;
    color: var(--primary-700);
}

.Dropdown button {
    /* width: 130px; */
}

.Dropdown button.Secondary {
    color: var(--primary-500);
    border-color: var(--primary-500);
}

.Dropdown input[type="range"]::-webkit-slider-runnable-track {
    background: var(--primary-200);
}

.Dropdown input[type="range"]::-moz-range-track {
    background: var(--primary-200);
}

.Dropdown input[type="range"]::-webkit-slider-thumb {
    border-color: var(--primary-700);
}