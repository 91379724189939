:root {
    --primary-010: hsl(208, 36%, 99%);
    --primary-100: hsl(208, 36%, 90%);
    --primary-200: hsl(208, 36%, 80%);
    --primary-300: hsl(208, 36%, 70%);
    --primary-400: hsl(208, 36%, 60%);
    --primary-500: hsl(208, 36%, 50%);
    --primary-600: hsl(208, 36%, 40%);
    --primary-700: hsl(208, 36%, 30%);
    --primary-800: hsl(208, 36%, 20%);
    --primary-900: hsl(208, 36%, 10%);

    --grey-010: hsl(0, 0%, 99%);
    --grey-050: hsl(0, 0%, 95%);
    --grey-100: hsl(0, 0%, 90%);
    --grey-200: hsl(0, 0%, 80%);
    --grey-300: hsl(0, 0%, 70%);
    --grey-400: hsl(0, 0%, 60%);
    --grey-500: hsl(0, 0%, 50%);
    --grey-600: hsl(0, 0%, 40%);
    --grey-700: hsl(0, 0%, 30%);
    --grey-800: hsl(0, 0%, 20%);
    --grey-900: hsl(0, 0%, 10%);


    --error-color: red;

    --corner-radius: 8px;
    --motion: 0.5;
    --speed: 1;
}