.SignedInDepartments {
    border: 0;
    padding: 0;
    color: inherit;
}

.SignedInDepartments:active {
    transform: none;
}

.SignedInDepartmentsText {
    color: currentColor;
    font-weight: normal;
    text-align: left;
    margin-bottom: 8px;
}

.SignedInDepartmentsIcons {
    display: flex;
    flex-wrap: wrap;
    max-width: 160px;
    gap: 8px;
}

.SignedInDepartmentsIcons:empty::before {
    content: "None";
}

.SignedInDepartments .SignedInDepartmentsIcons :is(svg, img, :global(.svg-mimic)) {
    height: 24px;
    width: 24px;
}

.SignedInDepartmentsIcons svg * {
    fill: currentColor;
}

.DepartmentSelector {
    background-color: var(--primary-010);
}

@media only screen and (max-width: 600px) {
    .SignedInDepartments .SignedInDepartmentsIcons :is(svg, img, :global(.svg-mimic)) {
        height: 20px;
        width: 20px;
    }
}