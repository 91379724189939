.DepartmentNavWrapper {
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-direction: column;
    gap: 16px;
}


.DepartmentNav {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 48px;


    overflow-y: overlay;
}

.Button {
    width: fit-content;
    margin: auto;
    flex-shrink: 0;
}