
.Engage {
    width: 100%;
    /* text-align: center; */
}

.Engage :is(svg, img, :global(.svg-mimic)) {
    height: 3em;
    margin-top: -1em;
    margin-bottom: -1em;
}

.Engage svg * {
    fill: var(--grey-200);
}

.Engaged svg * {
    fill: var(--primary-500);
}