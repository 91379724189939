.ArrowBtn {
    box-sizing: border-box;
    padding: 16px;
    border: 0;
}

.ArrowBtn svg {
    width: 100%;
    height: 100%;
}


.ArrowBtn svg > * {
    stroke: var(--grey-300);
    stroke-width: 5px;
}

.Up {
    transform: rotate(180deg);
}

.Down {
    transform: rotate(0deg);
}

.Left {
    transform: rotate(90deg);
}

.Right {
    transform: rotate(270deg);
}