.DepartmentSelectorWrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    
    gap: 16px;
}

.Column .DepartmentSelectorWrapper {
    flex-direction: column;
}

.Row .DepartmentSelectorWrapper {
    flex-direction: row;
}


.DepartmentSelector {
    height: 100%;
    padding: 32px;
    box-sizing: border-box;


    overflow-y: overlay;
}

.DepartmentSelectorWrapper > * {
    flex-shrink: 0;
}
