.LoadText {
    --base-color: rgba(0, 0, 0, calc(0.05 * var(--strength)));
    --highlight-color: rgba(0, 0, 0, calc(0.01 * var(--strength)));
    display: inline-block;
    height: var(--height);
    width: var(--width);
    border-radius: 5px;
    animation-name: LoadText;
    animation-duration: 1.75s;
    animation-delay: var(--delay);
    animation-iteration-count: infinite;
    background-image: linear-gradient(90deg, var(--base-color) 0%, var(--base-color) 25%, var(--highlight-color) 50%, var(--base-color) 75%, var(--base-color) 100%);
    background-size: 400% 100%;
    background-position: 0 0;
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.LoadText.Light {
    --base-color: rgba(255, 255, 255, calc(0.05 * var(--strength)));
    --highlight-color: rgba(255, 255, 255, calc(0.01 * var(--strength)));
}

@keyframes LoadText {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100% 0;
    }
}