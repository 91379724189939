.Header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 50px;
    padding: 0 32px;
    background: linear-gradient(90deg, var(--primary-200) 50%, var(--primary-500));

}

.Text {
    text-align: left;
    padding: 24px 0;
    font-size: 22px;
}

.Icon {
    height: 50px;
    width: 50px;
    color: rgba(255, 255, 255, 0.15);
}

.Icon svg > * {
    fill: currentColor;
}