
.HeaderNav {
    padding-left: 48px;
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    gap: 64px;
    grid-template-columns: repeat(4, 36px) 1fr;
    color: var(--grey-400);
}

.HeaderNav a:link,
.HeaderNav a:visited {
    color: currentColor;
}

.HeaderNav :is(svg, img, :global(.svg-mimic)) {
    width: 100%;
}

.HeaderNav .Logo {
    justify-self: end;
    height: 60%;
    margin-right: 32px;
}

.Icon {
    width: 36px;
    height: 36px;
}

.Icon svg {
    width: 100%;
    height: 100%;
}

.Icon > svg *,
.Icon > span > svg *,
.Icon > div > button svg * {
    fill: currentColor;
    transition: fill calc(200ms * var(--speed));
}

.Icon:hover > span > svg *,
.Icon:hover > span > svg *,
.Icon:hover > div > button svg * {
    fill: currentColor;
}

.Icon.Active svg * {
    fill: var(--primary-500);
}


.Location {
    justify-self: end;
    height: 45%;
    margin-right: 32px;
}

.Location :is(svg, img) {
    height: 100%;
}

.Account > * {
    fill: var(--grey-300) !important;
}

.SettingsToggle, .MyPagesToggle {
    display: none;
}

.Account {
    display: none;
    border: 0;
    padding: 0;
    color: currentColor;
}

.SiteSelector button {
    color: var(--grey-400) !important;
    
}


@media only screen and (max-width: 1100px) {
    .HeaderNav {
        gap: 32px;
    }

    .Icon {
        width: 28px;
        height: 28px;
    }
    
}

:global(.mobile) .HeaderNav {
    grid-template-columns: minmax(0, 1fr) 36px 36px;
    grid-template-areas: 'locations settings account';
    padding: 0px 32px;
    gap: 32px;
    width: 100vw;
    background-color: var(--primary-500);
    color: var(--primary-010);
    justify-items: start;
}

:global(.mobile) .Departments {
    grid-area: locations;
    /* pointer-events: none; */
}

/* .Icon > svg * ,
.Icon > div > button > svg * {
    fill: currentColor;
} */

:global(.mobile) .Icon {
    display: none;
}

:global(.mobile) .Account {
    grid-area: account;
    display: block;
}

:global(.mobile) .SettingsToggle, :global(.mobile) .MyPagesToggle {
    grid-area: settings;
    display: block;
}

:global(.mobile) .MyPagesToggle:not(.Active) ~ .SettingsToggle:not(.Acitve) {
    display: none;
}

:global(.mobile) .Active:is(.SettingsToggle, .MyPagesToggle) {
    display: none;
}