.SiteSelector {
    position: relative;
    height: 100%;
}

.Icon {
    border: 0;
    padding: 0;
    height: 100%;
    display: grid;
    place-items: center;
}

.Icon:active {
    transform: none;
}

.Options {
    display: grid;
    grid-template-rows: 1fr 1fr;
}


.Option :is(svg, img, :global(.svg-mimic)) {
    width: 100%;
}

.Option:first-child {
    border-top-left-radius: calc(var(--corner-radius) * 0.75);
    border-top-right-radius: calc(var(--corner-radius) * 0.75);
}

.Option:last-child {
    border-bottom-left-radius: calc(var(--corner-radius) * 0.75);
    border-bottom-right-radius: calc(var(--corner-radius) * 0.75);
}

.Option {
    border: none;
    border-radius: 0;
}

.Option:hover {
    background-color: var(--grey-050);
}

.Option:active {
    transform: none;
}

.Option.Active {
    background-color: var(--primary-100);
}
