.Select {
    position: relative;
}

.InputWrapper {
    position: relative;
}

.InputWrapper > input {
    width: 100%;
    box-sizing: border-box;
}

/* .NoAnimate, .InputWrapper,
.NoAnimate, .ArrowWrapper {
    transition-duration: 0s !important;
} */

.InputWrapper,
.ArrowWrapper {
    z-index: 1;
    transition: z-index 150ms step-end
}

/* Only apply this when this drop down is
down so others can appear overtop of it */
.Expanded .InputWrapper,
.Expanded .ArrowWrapper {
    z-index: 3;
    transition: z-index 150ms step-start
}

.ArrowWrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 100%;
    padding-right: 8px;
}

.Arrow {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0 !important;
    box-shadow: none !important;
}

.Drop {
    position: fixed;
    z-index: 2;

    /* Width is set by JS */
    max-height: 300px;

    box-sizing: border-box;
    margin-top: 8px;
    background-color: white;
    
    border: 2px solid hsl(0, 0%, 80%);
    border-radius: var(--corner-radius);

    overflow-y: overlay;
    
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

/* .NoAnimate .Drop {
    animation-duration: 0s !important;
} */


.Mounted .Drop {
    transform-origin: top;

    animation-name: Out;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-duration: 150ms;
    animation-direction: normal;
}

.Mounted.Expanded .Drop {
    animation-name: In;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-duration: 150ms;
    animation-direction: normal;
}

@keyframes Out {
    0% {
        opacity: 1;
        transform: translateY(0px) scaleY(1);
        height: unset;
    }

    99.99% {
       
        opacity: 0;
        transform: translate3d(0, -45px, 0) scaleY(95%);
        pointer-events: none;
        height: unset;
    }

    100% {
        height: 0;
        opacity: 0;
        pointer-events: none;
    }
}

@keyframes In {
    0% {
        height: 0;
        opacity: 0;
        pointer-events: none;
    }

    0.01% {
        opacity: 0;
        transform: translate3d(0, -45px, 0) scaleY(95%);
        pointer-events: none;
        height: unset;
    }

    100% {
        opacity: 1;
        transform: translateY(0px) scaleY(1);
        height: unset;
    }
}

.Option {
    width: 100%;
    box-sizing: border-box;
    padding: 16px !important;
    margin: 0 !important;
    box-shadow: none !important;
    color: grey;
    border: 0;
    border-radius: 0;
    text-align: left;
    font-weight: normal;
    /* transition: background-color calc(150ms * var(--speed)), color calc(150ms * var(--speed)); */
}

.Option:not(.Locked):hover {
    background-color: hsl(0, 0%, 99%);
    cursor: pointer;
}

.Option:not(.Locked).Highlighted {
    background-color: var(--primary-100);
}

.Option:not(.Locked).Selected {
    background-color: var(--primary-500);
    color: white;
    font-weight: bold;
}

.Option:disabled {
    opacity: 0.5;
}

.Option:active {
    transform: none;
}

.Option:first-child {
    border-top-left-radius: calc(var(--corner-radius) * 0.75);
    border-top-right-radius: calc(var(--corner-radius) * 0.75);
}

.Option:last-child {
    border-bottom-left-radius: calc(var(--corner-radius) * 0.75);
    border-bottom-right-radius: calc(var(--corner-radius) * 0.75);
}


.Drop::-webkit-scrollbar {
    width: 12px;
}

.Drop::-webkit-scrollbar-track {
    background: transparent;
}

.Drop::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 99999px;
}

.Drop::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}