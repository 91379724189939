.Table {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
}

.Row {
    display: grid;
    grid-template-columns: var(--template);
    background-color: white;
}

.Row:not(.Header):nth-child(odd) {
    background-color: var(--grey-050);
}

.Header {
    background-color: var(--primary-500);
    color: var(--primary-100);
    font-weight: bold;
}

.Body {
    overflow: overlay;
    height: 100%;
}

.Header .Cell {
    font-size: 16px;
}

.Header .Cell:empty {
    padding: 0;
}

.Cell {
    padding: 16px;
    font-size: 14px;
}

.Cell.Message {
    grid-column: 1 / -1;
    text-align: center;
    font-size: 1.15em;
}