.MyPages {
    height: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    /* background-color: var(--grey-050); */

}

.MyPages.Mobile {
    background-color: var(--primary-100);
    padding: 24px;
    overflow-y: overlay;
}

.Engage {
    width: 100%;
    /* text-align: center; */
}

.Engage > :is(svg, img, :global(.svg-mimic)) {
    height: 3em;
    margin-top: -1em;
    margin-bottom: -1em;
}

.Engage svg * {
    fill: var(--grey-200);
}

.Engaged svg * {
    fill: var(--primary-500);
}

.NoPagesMessage {
    text-align: center;
    color: var(--primary-500);
}


div.CancelBtnCell {
    display: grid;
    padding: 10px;
}

.CancelBtn {
    padding: 2px 4px;
    font-size: 14px;
}

:global(.mobile) .MyPages {
    display: block;
}