.WorkRequests {
    height: 100%;
    display: grid;
    grid-template-rows: 70px minmax(0, 1fr);
    /* background-color: var(--grey-050); */
}

.WorkRequests > aside {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
}

.WorkRequests main {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    height: 100%;
    overflow: hidden;
}
.Tabs {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
}

.Tab {
    border: 0;
    background-color: var(--primary-500);
    border-radius: 0;
    color: var(--primary-010);
}

.Tab.Active {
    background-color: var(--primary-400);
}

.Tab:active {
    transform: none;
}

.TabContent:not(.Active) {
    display: none;
}

@media only screen and (min-width: 1150px)  {
    .WorkRequests {
        grid-template: auto minmax(0, 1fr) / minmax(0, 1fr) minmax(400px, 30%);
    }
    
    .Tabs {
        display: none;
    }

    main.TabContent {
        grid-row: span 2;
    }

    .TabContent:not(.Active) {
        display: block;
    }
}