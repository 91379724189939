.QuickPages {
    height: 100%;
}

.DepartmentsText {
    font-weight: bold;
    font-size: 22px;
    margin-right: 32px;
}

.DepartmentsTextWrapper {
    display: grid;
    height: 100%;
    align-items: center;
}

.Departments > div {
    padding: 0;
}