body, html, #root {
    margin: 0;
    height: 100vh;
    font-family: /*'Poppins', */ -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

svg {
    paint-order: stroke;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #a5bbce;
}

::-webkit-scrollbar-thumb {
    background: #FFFFFFB2;
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 99999px;
}

::-webkit-scrollbar-thumb:hover {
    background: white;
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}



::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 99999px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

label {
    display: block;
}

input[type='date'],
input[type='datetime-local'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
textarea,
button,
.input-mimic,
.textarea-mimic,
.button-mimic {
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-family: inherit;
    border: 0;
    padding: 12px 16px;
    border-radius: var(--corner-radius);
    border: 2px solid var(--grey-100);
    transition: border-color 200ms;
}

:is(
    input[type='date'],
    input[type='datetime-local'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    input[type='time'],
    input[type='url'],
    textarea,
)::placeholder {
    color: var(--grey-200);
}

label > :is(
    input[type='date'],
    input[type='datetime-local'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    input[type='time'],
    input[type='url'],
    textarea,
    button,
    .input-mimic,
    .textarea-mimic,
    .button-mimic
) {
    margin-top: 8px;
}

:is(
    input[type='date'],
    input[type='datetime-local'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    input[type='time'],
    input[type='url'],
    textarea,
    .input-mimic,
    .textarea-mimic
):focus {
    outline: none;
    border-color: var(--grey-200);
}

button,
.button-mimic {
    background-color: transparent;
    border-color: var(--primary-500);
    /* outline: 0px dashed var(--primary-700); */

    color: var(--primary-500);
    font-weight: bold;
    font-size: 14px;

    cursor: pointer;
    transition: transform calc(150ms * var(--speed)) ease-in-out, outline-width calc(250ms * var(--speed));
}

:is(
    button,
    .button-mimic
):focus {
    outline-width: 0;
    /* outline-width: 0; */
}
:is(
    button,
    .button-mimic
):focus-visible {
    /* outline-width: 3px; */
}



:is(
    button,
    .button-mimic
).primary {
    background-color: var(--primary-500);
    color: var(--primary-100);
    border-color: var(--primary-500);
}

:is(
    button,
    .button-mimic
).primary:focus {
    border-color: var(--primary-500);
}

:is(
    button,
    .button-mimic
):active {
    transform: scale(calc(100% - (var(--motion) * 10%)));
}

:is(
    button,
    .button-mimic
):disabled {
    transform: none !important;
    border-color: var(--grey-300);
    color: var(--grey-300);

}

:is(
    button,
    .button-mimic
):disabled.primary {
    background-color: var(--grey-300);
    border-color: var(--grey-300);
    color: var(--grey-100);
}

label {
    margin: 24px 0;
    font-weight: bold;
    font-size: 14px;
    color: var(--grey-400);
}

label:first-child {
    margin-top: 0;
}

label:last-child {
    margin-bottom: 0;
}


*:focus-visible {
    position: relative;
    outline: none;
}

*:focus-visible::before {
    content: "";
    position: absolute;
    top: 0; left: 0;

    width: 100%;
    height: 100%;

    border-radius: inherit;
    outline: 4px dashed white;
    mix-blend-mode: difference;
}

* {
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 15rem;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track {
    background: white;
    border-radius: 10px;
    height: 0.35rem;
}
  
/******** Firefox ********/
input[type="range"]::-moz-range-track {
    background: white;
    border-radius: 10px;
    height: 0.35rem;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -9px; /* Centers thumb on the track */
    background-color: var(--primary-700);
    border: 3px solid white;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 1em;
}