.MobileNavPage {
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;

    top: 0;
    left: 0;

    padding: 32px;

    color: var(--primary-010);

    overflow-y: overlay;

    background-color: var(--primary-600);
}

.MobileNavPage:not(.Shown) {
    display: none;
}

.MobileNavPage h1 {
    font-size: 22px;
    margin-top: 32px;
}

.MobileNavPage > h1:first-of-type {
    margin-top: 0;
    font-size: 32px;
}

.Pages {
    display: grid;
    grid-template-columns: repeat(auto-fill, 68px);
    justify-content: space-around;
    gap: 16px;
    place-items: center;
}

.DepartmentLink {
    padding: 0;
    background-color: transparent;
}

.Icon, .DepartmentLink {
    display: grid;
    place-items: center;
    width: 68px;
    height: 68px;
    padding: 0px;
    box-sizing: border-box;
    opacity: 1;
    border-radius: var(--corner-radius);
}

.Icon > :is(svg img :global(.svg-mimic)) {
    width: 100%;
    height: 50%;
}

button.Icon {
    padding: 0;
}

.Pages svg > * {
    fill: var(--primary-010);
}

.Pages .Active {
    background-color: var(--primary-300);
}


.Site {
    margin-bottom: 32px;
    color: white;
}

.SignedInDepartments {
    background-color: white;
    border-radius: 15px;
    color: black;
    padding: 32px;
}

.SignedInDepartments > h1 {
    margin-top: 0;
}

.SignedInDepartments > div {
    padding: 0;
}

.SignedInDepartments > div > div {
    display: grid;
    grid-template-columns: repeat(auto-fill, 68px);
    width: 100%;
    height: unset;
}

.X {
    position: absolute;
    top: 24px;
    right: 24px;

    width: 36px;
    height: 36px;
    
    border: 0;
    padding: 8px;
}

.X svg * {
    stroke: var(--primary-010);
}

.SignoutBtn {
    margin-top: 32px;
    background-color: var(--primary-010) !important;
    border-color: var(--primary-010) !important;
    color: var(--primary-500) !important;
}