.DepartmentButton {
    width: 68px;
    height: 68px;

    display: grid;
    place-items: center;
    box-sizing: border-box;
    
    transform-origin: center;
    transform-box: fill-box;

    border: none;
    padding: 0;

    background-color: var(--primary-010);
    border-radius: var(--corner-radius);
    cursor: pointer;

    fill: var(--primary-500) !important;
    opacity: 0.5;
    transition: transform calc(150ms * var(--speed)) ease-in-out, opacity calc(250ms * var(--speed));
}

.DepartmentButton:hover {
    opacity: 0.75;
}
.DepartmentButton.Active {
    opacity: 1;
}

.DepartmentButton:active {
    transform: scale(calc(100% - (var(--motion) * 10%)));
}